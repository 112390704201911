app.dm.define("Content", {
    "hash":     "@string",
    "tag":      "string",
    "title":    "string",
    "subtitle": "string",
    "duration": "number",
    "qrcode":   "boolean",
    "ribbon":   "boolean",
    "info":     "boolean",
    "type":     "string",
    "url":      "string",
    "group":    "string"
});
